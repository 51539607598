import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import {thunk_action_createCategoryList} from '../../../../redux/actions/categoriesListAction/categoriesListaction';



const OttAddCategory = ({ handleCategory }) => {
    const dispatch = useDispatch();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [addcategory, setcategory] = useState('');
    const [categoryError, setcategoryErr] = useState('');

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
        setcategory('');
        setcategoryErr('')
    }

    const handleonchange = (event) => {
        setcategoryErr('');
        setcategory(event.target.value)
    }

    const handleCategorySubmit = async () => {
        if (addcategory === '') {
            setcategoryErr('please add category');
        } else {
            let data = {
                "categoryname": addcategory.toLocaleLowerCase(),
                "categorytype": "ottupdates"
            }
            const response = await dispatch(thunk_action_createCategoryList(data));
            if (response?.data === undefined) {
                setcategoryErr('category already exist')
            } else {
                await handleCategory();
                setcategoryErr('');
                closeModal();
            }
        }

    }


    return (
        <div>
            <button type="button" className="btn btn-primary mb-3" onClick={openModal}>+ Category</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="content"
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <div className="text-end">
                    <span className="closeIcon" aria-hidden="true" onClick={() => closeModal()}>&times;</span>
                </div>
                {/* <div>I am a modal</div> */}
                <div className="row mt-4">

                    <div id="uploadImg">
                        <label htmlFor="imageInput" className="form-label text-start d-block">Category Name </label>
                        <input type="text" className="form-control" id="categoryname"  name="image"
                             onChange={(e) => handleonchange(e)} />
                        {categoryError !== '' ? <p style={{ color: 'red' }}>{categoryError}</p> : null}
                        <div className="text-end mt-3">
                            <button type="button" className="btn btn-primary mb-3" onClick={() => handleCategorySubmit()}>Submit</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default OttAddCategory;