import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import Slider from "react-slick";
import Modal from 'react-modal';
import moment from 'moment';
import { deleteFile } from 'react-s3';
import { uploadFile } from 'react-s3';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import commonConfig from "../../../../config/common-config";
import UploadOttUpdate from '../uploadOttUpdate';
import OttAddCategory from '../addcategory/addcategory';
import { thunk_action_getOttUpdateData, thunk_action_deleteOttUpdateData, thunk_action_EditOttUpdateData } from '../../../../redux/actions/ottUpdatesAction/ottUpdateAction'
import { thunk_action_getuserdeatils } from '../../../../redux/actions/loginAction/loginfetchaction';
import { thunk_action_getCategoryList } from '../../../../redux/actions/categoriesListAction/categoriesListaction';
import ViewAllCategories from '../../categories/categorieslist';
// import './categories.css';

window.Buffer = window.Buffer || require("buffer").Buffer;



const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Number of items to show at a time
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
            },
        },
    ],
};
const customStyles = {
    content: {
        width: '60%', // Adjust the width as needed
        margin: 'auto', // Center the modal horizontally
    },
};
const OttCategories = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const limit = 12;
    const [categoriesList, setCategoriesList] = useState([]);
    const [activeClass, setActiveclass] = useState(0);
    const [activeCategory, setactiveCategory] = useState(categoriesList[0]?.id || '');
    const [ottupdatelist, setottupdatelist] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [deleteData, setDeleteData] = useState({});
    const [errormsg, seterrormsg] = useState('');
    const [offset, setOffset] = useState(0);
    const [paginationId, setpaginationId] = useState(1);
    const [isCheck, setIsCheck] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [editmodalIsOpen, seteditmodalIsOpen] = useState(false);
    const [editData, seteditData] = useState([]);
    const [formData, setFormData] = useState({
        id: '',
        movieName: '',
        releaseDate: '',
        movieStory: '',
        shortDescription: '',
        youtubeLink: '',
        thumbnail: '',
        youtubethumbnail: '',
        otticonlogo: ''
    });
    const [errors, setErrors] = useState({
        movieName: '',
        releaseDate: '',
        movieStory: '',
        shortDescription: '',
        youtubeLink: '',
        thumbnail: '',
        youtubethumbnail: '',
        otticonlogo: ''
    });
    const [viewcategories, setviewcategories] = useState(false);
    // const totalPages = Math.ceil(totalCount / limit);
    const totalPages = Array.from({ length: Math.ceil(totalCount / limit) }, (_, index) => index + 1);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(10);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const pageNumberLimit = 10;

    useEffect(() => {
        dispatch(thunk_action_getuserdeatils());
        handleCategoryFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCategoryFetch = async () => {
        const locationpath = location?.pathname || '';
        const type = locationpath.slice(1);
        let data = {
            "categorytype": type
        }
        const response = await dispatch(thunk_action_getCategoryList(data));
        let result = response.data?.results || [];
        await setCategoriesList(result);
        await setactiveCategory(result[0]?.id);
        await handleOttUpdateFetch(result[0]?.id, offset);
    }

    const handleOttUpdateFetch = async (categoryId, offsetValue) => {
        let data = {
            categoryId: categoryId,
            limit: limit,
            offset: offsetValue
        }
        const response = await dispatch(thunk_action_getOttUpdateData(data));
        let result = response.data?.results || [];
        await setTotalCount(response.data?.totalcount || 0);
        await setottupdatelist(result);
    }


    const handleActive = async (id, index) => {
        if (activeClass !== index) {
            setIsCheckAll(false);
            setIsCheck([]);
            seteditData([]);
            setpaginationId(1);
            setOffset(0);
        }
        let offesetValue = activeClass !== index ? 0 : offset
        setActiveclass(index);
        setactiveCategory(id);
        setmaxPageNumberLimit(10);
        setminPageNumberLimit(0);
        await handleOttUpdateFetch(id, offesetValue);
    }

    const handleSubmit = async () => {
        await handleOttUpdateFetch(activeCategory, offset);
    }

    const handleCategory = async () => {
        await handleCategoryFetch();
    }



    const handleDelete = async () => {
        const response = await dispatch(thunk_action_deleteOttUpdateData(isCheck));
        // Delete selected items
        if (response) {
            setIsCheckAll(false);
            setIsCheck([]);
            seteditData([]);
            setpaginationId(1);
            await handleOttUpdateFetch(activeCategory, 0);
            await closedeleteModal();
            //   setSelectedItems([]);
            seterrormsg('');
            notify("Ott Deleted Successfully", 'success');
        } else {
            seterrormsg('Some error occur while deleting0');
        }
    };


    const openDeleteModal = (data) => {
        setDeleteData(data);
        setIsOpen(true);
    }

    const closedeleteModal = () => {
        setIsOpen(false);
    }

    const handleChecked = (objId, event) => {
        const { id, checked } = event.target;
        setIsCheck([...isCheck, id]);
        seteditData([...editData, objId]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
            seteditData(editData.filter(item => item?.id !== id));
        }

    }

    const handleSelectAll = (e) => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(ottupdatelist.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
            seteditData([]);
        }
    };

    const handleNextPage = async () => {
        setIsCheckAll(false);
        setIsCheck([]);
        seteditData([]);
        if (paginationId < totalPages?.length) {
            let offsetVal = paginationId * limit;
            setOffset(offsetVal);
            setpaginationId(paginationId + 1);
            await handleOttUpdateFetch(activeCategory, offsetVal);
        } else {
            return null
        }
        if (paginationId + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const handlePrevPage = async () => {
        setIsCheckAll(false);
        setIsCheck([]);
        seteditData([]);
        if (paginationId > 1) {
            let offsetVal = (paginationId - 2) * limit;
            setOffset(offsetVal);
            setpaginationId(paginationId - 1);
            await handleOttUpdateFetch(activeCategory, offsetVal);
        } else {
            return null
        }
        if ((paginationId - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };

    const handlePageChange = async (id, index) => {
        if (paginationId !== id) {
            setIsCheckAll(false);
            setIsCheck([]);
            seteditData([]);
        }
        setpaginationId(id);
        let offsetVal = index * limit;
        setOffset(offsetVal);
        setFormData({
            id: '',
            movieName: '',
            releaseDate: '',
            movieStory: '',
            shortDescription: '',
            youtubeLink: '',
            thumbnail: '',
            youtubethumbnail: '',
        })
        await handleOttUpdateFetch(activeCategory, offsetVal);
    }

    const handleThumbUpload = async (event) => {

        const uploadData = event.target.files;

        const updatedUploadData = Array.from(uploadData).map((element) => {

            const fileNameWithoutExtension = element.name.split('.').slice(0, -1).join('.'); // Remove extension

            const newFilename = `${fileNameWithoutExtension}.${element.name.split('.').pop()}`; // Add timestamp before extension

            return new File([element], newFilename, { type: element.type });

        });

        let config = commonConfig.config;

        let bucketUrls = [];

        for (const obj of updatedUploadData) {

            try {

                // eslint-disable-next-line

                const data = await uploadFile(obj, config);




                bucketUrls.push(obj); // Assuming data has a 'location' property

            } catch (err) {

                console.error(err, 'err5666');

            }

        }

        console.log(bucketUrls, 'bucketUrls')

    }

    const handleEditopen = async () => {
        let thumbnailurl = editData[0]?.thumbnail;
        let youtubethumbnailurl = editData[0]?.youtube_thumbnail;
        let thumbnail = thumbnailurl?.substring(thumbnailurl?.lastIndexOf("/") + 1);
        let youtubethumbnail = youtubethumbnailurl?.substring(youtubethumbnailurl?.lastIndexOf("/") + 1);

        setFormData({
            //  ...formData,
            id: editData[0]?.id,
            movieName: editData[0]?.moviename,
            releaseDate: editData[0]?.releasedate,
            movieStory: editData[0]?.moviestory,
            shortDescription: editData[0]?.short_description,
            youtubeLink: editData[0]?.youtubelink,
            thumbnail: thumbnail,
            youtubethumbnail: youtubethumbnail,
        });
        seteditmodalIsOpen(true);
    }

    const closeEditModal = async () => {
        setErrors({
            movieName: '',
            releaseDate: '',
            movieStory: '',
            shortDescription: '',
            youtubeLink: '',
            thumbnail: '',
            youtubethumbnail: '',
            otticonlogo: ''
        });
        seteditmodalIsOpen(false);
    }
    const handleInputchange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        // Clear the corresponding error when user starts typing in a field
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const validateRequest = () => {
        let newErrors = {};

        // Validate movieName
        if (formData.movieName === '') {
            newErrors = { ...newErrors, movieName: 'Movie Name is required' };
        }

        // Validate releaseDate
        if (formData.releaseDate === '') {
            newErrors = { ...newErrors, releaseDate: 'Release Date is required' };
        }
        // Validate youtubelink
        if (formData.youtubeLink === '') {
            newErrors = { ...newErrors, youtubeLink: 'Youtube link is required' };
        }

        // Validate movieStory
        if (formData.movieStory === '') {
            newErrors = { ...newErrors, movieStory: 'Movie Story is required' };
        }

        // Validate shortDescription
        if (formData.shortDescription === '') {
            newErrors = { ...newErrors, shortDescription: 'Short Description is required' };
        }
        // Validate thumbnail
        if (formData.thumbnail === '') {
            newErrors = { ...newErrors, thumbnail: 'Thumbnail is required' };
        }

        // Validate youtubethumbnail (assuming it's required)
        if (formData.youtubethumbnail === '') {
            newErrors = { ...newErrors, youtubethumbnail: 'YouTube Thumbnail is required' };
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    };

    const notify = (message, type) => {
        if (type === 'success') {
            toast.success(message);
        } else if (type === 'error') {
            toast.error(message);
        } else {
            toast(message);
        }
    };

    const handleUpdateSubmit = async () => {
        const validate = validateRequest();
        if (validate) {
            const response = await dispatch(thunk_action_EditOttUpdateData(formData));
            if (response) {
                await handleOttUpdateFetch(activeCategory, offset);
                setIsCheckAll(false);
                setIsCheck([]);
                seteditData([]);
                closeEditModal();
                notify("Ott Updated Successfully", 'success');
            } else {
                notify("Something went wrong", 'error')
            }
            console.log('response', response);
        } else {
            console.log('dataerror', errors);
        }
    }

    const handleUploadImage = async (event, id) => {
        try {
            const uploadData = event.target.files[0];

            if (uploadData) {
                // Generate a timestamp
                const timestamp = new Date().getTime();

                // Get the file name without extension
                const fileNameWithoutExtension = uploadData.name.split('.').slice(0, -1).join('.');

                // Create a new filename by appending the timestamp and the original extension
                const newFilename = `${fileNameWithoutExtension}_${timestamp}.${uploadData.name.split('.').pop()}`;

                // Create a new File object with the updated name
                const updatedFile = new File([uploadData], newFilename, { type: uploadData.type });

                let config = commonConfig.config;
                // Call your upload function or API endpoint to upload the file
                await uploadFile(updatedFile, config);
                if (id === 'thumbnail') {
                    await setFormData({
                        ...formData,
                        thumbnail: newFilename
                    });
                } else if (id === 'youtubethumbnail') {
                    await setFormData({
                        ...formData,
                        youtubethumbnail: newFilename
                    });
                }

            }
        } catch (error) {
            console.error("Error during file upload:", error);
        }
    };
    const handleViewallCategories = async () => {
        await handleCategoryFetch();
        setActiveclass(0);
        setviewcategories(!viewcategories)
    }

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-end">
                <div className="text-center mx-2">
                    <button type="button" className="btn btn-primary mb-3"
                        onClick={() => handleViewallCategories()}
                    >{viewcategories ? 'View ottupdates' : 'View all categories'}</button>
                </div>
                {!viewcategories &&
                    <div className="text-center">
                        <OttAddCategory handleCategory={handleCategory} />
                    </div>
                }
            </div>
            {viewcategories &&
                <ViewAllCategories categoriesList={categoriesList} />
            }
            {!viewcategories &&
                <div>
                    <div>
                        {categoriesList.length > 4 ?
                            <Slider {...settings}>
                                {categoriesList.map((obj, index) => (
                                    <div className={activeClass === index ? 'nav-link categoryNav capitalLetter' : 'nav-link pointerHand capitalLetter'}
                                        aria-current="page" key={index}
                                        onClick={() => handleActive(obj.id, index)}
                                    >{obj.categoryname}</div>
                                ))}
                            </Slider>
                            :
                            <div className="row categoryGrid">
                                {categoriesList.map((obj, index) => (
                                    <div className={activeClass === index ? 'nav-link categoryNav capitalLetter' : 'nav-link pointerHand capitalLetter'}
                                        aria-current="page" key={index}
                                        onClick={() => handleActive(obj.id, index)}
                                    >{obj.categoryname}</div>
                                ))}
                            </div>
                        }

                    </div>
                    {categoriesList.length > 0 ?
                        <div>
                            {ottupdatelist.length > 0 &&
                                <div>
                                    {isCheck.length > 0 ? (
                                        <div>
                                            <i className="fa fa-trash-o trashIcon mt-3" onClick={openDeleteModal}></i>
                                            <i
                                                className={`fa fa-edit ms-3 ${isCheck.length === 1 ? 'editIcon' : 'editIcondisable'
                                                    }`}
                                                onClick={() => isCheck.length === 1 ? handleEditopen() : undefined}
                                            />
                                        </div>
                                    ) : (
                                        <div className="d-flex">
                                            <i className="fa fa-trash-o trashIcondisable mt-3" />
                                            <i className="fa fa-edit ms-3 editIcondisable" />
                                        </div>
                                    )}
                                    <input className="form-check-input mt-3" type="checkbox"
                                        id='selectedall' onChange={(event) => handleSelectAll(event)}
                                        checked={isCheckAll} />


                                    <div className="row">
                                        {ottupdatelist.map((obj, index) => (
                                            <div className="col-lg-2 col-md-4 col-sm-4 col-6 mt-4 d-flex" key={index}>
                                                <div className="me-2">
                                                    <input className="form-check-input" type="checkbox" checked={isCheck.includes(obj.id)} name={obj.id}
                                                        id={obj.id} onChange={(event) => handleChecked(obj, event)} />
                                                </div>
                                                <div className="card">
                                                    <img src={obj.thumbnail} alt="img" className="card-img-top" style={{ height: '150px' }} />
                                                    <div className="card-body p-2">
                                                        <p className="m-0 capitalLetter" style={{ fontSize: '14px', fontWeight: '600' }}>{obj.moviename}</p>
                                                        <p className="m-0 text-center" style={{ fontSize: '12px' }}>{moment(obj.releasedate).format("DD MMMM YYYY")}</p>
                                                        <p className="m-0  text-center" style={{ fontSize: '12px' }}>
                                                            <a href={obj.youtubelink} target="blank">Youtube</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div> ||
                                <div className="text-center mt-4"> No data found</div>
                            }
                            {(ottupdatelist.length > 0 && totalCount > limit) &&
                                <nav aria-label="Page navigation example" className="mt-4 d-flex justify-content-center">
                                    <ul className="pagination">
                                        <li className={paginationId === 1 ? "page-item disabled" : "page-item"}
                                            onClick={() => handlePrevPage()}>
                                            <a className="page-link" href="#" aria-label="Previous" aria-disabled="true">
                                                <span aria-hidden="true">Previous</span>
                                            </a>
                                        </li>
                                        {totalPages.map((number, index) =>
                                            (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) ?
                                                (
                                                    <li key={index}
                                                        className={paginationId === index + 1 ? 'page-item active' : 'page-item'}
                                                        onClick={() => handlePageChange(index + 1, index)}>
                                                        <a className="page-link" href="#">
                                                            {index + 1}
                                                        </a>
                                                    </li>
                                                ) : null
                                        )}

                                        <li className={paginationId === totalPages?.length ? "page-item disabled" : "page-item"}
                                            onClick={() => handleNextPage()}>
                                            <a className="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">Next</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            }

                            <div className="text-start mt-4 col-lg-2 col-md-4 col-sm-4 col-6">
                                <UploadOttUpdate
                                    handleSubmit={handleSubmit}
                                    activeCategory={activeCategory} />
                            </div>
                        </div> :
                        <div className="text-center">Please add category</div>
                    }

                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closedeleteModal}
                        className="content"
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div className="float-end">
                            <span className="closeIcon" aria-hidden="true" onClick={() => closedeleteModal()}>&times;</span>
                        </div>
                        <div>
                            {errormsg !== '' ? <p>{errormsg}</p> : null}
                            <div className="text-center mt-4">Are you sure you want delete ?</div>
                            <div className="mt-4 text-end">
                                <button type="button" className="btn btn-primary mx-3 mb-3" onClick={() => closedeleteModal()}>Cancel</button>
                                <button type="button" className="btn btn-primary mx-3 mb-3" onClick={() => handleDelete()}>Ok</button>
                            </div>
                        </div>
                    </Modal>


                    <Modal
                        isOpen={editmodalIsOpen}
                        onRequestClose={closeEditModal}
                        className="content"
                        ariaHideApp={false}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >

                        <div className="float-end">
                            <span className="closeIcon" aria-hidden="true" onClick={() => closeEditModal()}>&times;</span>
                        </div>

                        <div className="custom-modal-header my-4">
                            <h4>Update OTT Data</h4>
                        </div>

                        <div>
                            <form>
                                <div className="mb-3 row">
                                    <div className="col-4">
                                        <label className="form-label redAsterisk">Moviename</label>
                                        <input type="text" className="form-control" id="movieName" aria-describedby="movieName" name="movieName"
                                            onChange={handleInputchange} value={formData.movieName} />
                                        {errors.movieName && <p className="error-message mt-2 invalidField">{errors.movieName}</p>}
                                    </div>
                                    <div className="col-4">
                                        <label className="form-label redAsterisk">Release date</label>
                                        <input type="date" className="form-control" id="releaseDate" aria-describedby="releaseDate" name="releaseDate"
                                            onChange={handleInputchange} value={moment(formData.releaseDate).format('YYYY-MM-DD')} />
                                        {errors.releaseDate && <p className="error-message mt-2 invalidField">{errors.releaseDate}</p>}
                                    </div>
                                    <div className="col-4">
                                        <label className="form-label redAsterisk">Youtubelink</label>
                                        <input type="text" className="form-control" id="youtubeLink" aria-describedby="youtubeLink" name="youtubeLink"
                                            onChange={handleInputchange} value={formData.youtubeLink} />
                                        {errors.youtubeLink && <p className="error-message mt-2 invalidField">{errors.youtubeLink}</p>}
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <div className="col-6">
                                        <label className="form-label redAsterisk">Movie story</label>
                                        <textarea type="text" className="form-control" id="movieStory" aria-describedby="movieStory" name="movieStory"
                                            onChange={handleInputchange} value={formData.movieStory} />
                                        {errors.movieStory && <p className="error-message mt-2 invalidField">{errors.movieStory}</p>}
                                    </div>
                                    <div className="col-6">
                                        <label className="form-label redAsterisk">Short description</label>
                                        <textarea type="text" className="form-control" id="shortDescription" aria-describedby="shortDescription" name="shortDescription"
                                            onChange={handleInputchange} value={formData.shortDescription} />
                                        {errors.shortDescription && <p className="error-message mt-2 invalidField">{errors.shortDescription}</p>}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <label className="form-label redAsterisk">Thumbnail</label>
                                        <input type="file" className="form-control" id="thumbnail" aria-describedby="thumbnail" accept=".png, .jpg, .jpeg, .webp"
                                            onChange={(event) => handleUploadImage(event, 'thumbnail')} />
                                        <a href={`${commonConfig.bucketUrl}${formData.thumbnail}`}
                                            target="_blank"
                                            className="mt-2 thumbnailimgs"
                                        >{formData.thumbnail}</a>
                                        {errors.youtubethumbnail && <p className="error-message mt-2 invalidField">{errors.youtubethumbnail}</p>}
                                    </div>
                                    <div className="col-6">
                                        <label className="form-label redAsterisk">Youtube thumbnail</label>
                                        <input type="file" className="form-control" id="youtubethumbnail" aria-describedby="youtubethumbnail"
                                            onChange={(event) => handleUploadImage(event, 'youtubethumbnail')} />
                                        <a
                                            href={`${commonConfig.bucketUrl}${formData.youtubethumbnail}`}
                                            target="_blank" className="mt-2 thumbnailimgs" >{formData.youtubethumbnail}</a>
                                        {errors.youtubethumbnail && <p className="error-message mt-2 invalidField">{errors.youtubethumbnail}</p>}
                                    </div>

                                </div>

                            </form>
                            <div className="mt-4 text-end">
                                {/* <button type="button" className="btn btn-primary mx-3 mb-3" onClick={() => closedeleteModal()}>Cancel</button> */}
                                <button type="button" className="btn btn-primary m-4" onClick={() => handleUpdateSubmit()}>Update</button>
                            </div>
                        </div>
                    </Modal>

                    <ToastContainer />
                    <div className="row mt-3">
                        <div className="col-lg-6 col-md-12 col-12">
                            <label>Please upload ott images here...</label>
                            <input type="file" className="form-control mt-3" id="imageInput" accept=".png, .jpg, .jpeg, .webp" name="image"
                                multiple onChange={(e) => handleThumbUpload(e)} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default OttCategories;