import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory,useLocation } from "react-router-dom";
import { thunk_action_getAllCounts } from '../../../redux/actions/stickersAction/stickerfetchaction';
import './home.css';
import SidebarComponent from '../sidebar/sidebar';
import HeaderComponent from '../header/header';

const Home = ({handleNavActive,menuName}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const totalCount = useSelector(state => state.getAllCounts?.results) || [];
    
    useEffect(() => {
        handleFetchData();
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFetchData = async () => {
        dispatch(thunk_action_getAllCounts());
    }

    const handleNavigate=(index,page)=>{
        history.push(`/${page}`)
    // handleNavActive(index,{ id: index, menuName: page });
    }

    return (
        <div className="container-fluid">
            <HeaderComponent />
            <div className="row flex-sm-nowrap">
         <SidebarComponent/>
        <div className="col-lg-10 col-md-9 col-sm-9 col-12 py-3" id="submenu1">
            <div className="row m-2">
                <div className="col-lg-3 col-md-5 col-sm-6 col-6 text-center my-md-2 my-2 countBox pointerHand" onClick={()=>handleNavigate(1,'stickers')}>
                    <h3>Stickers</h3>
                    <span className="countSize">{totalCount[0]?.stickers}</span>
                </div>
                <div className="col-lg-3 col-md-5 col-sm-6 col-5 text-center countBox my-md-2 mx-lg-3 mx-md-2 m-2 pointerHand" onClick={()=>handleNavigate(2,'clips')}>
                    <h3>Clips</h3>
                    <span className="countSize">{totalCount[1]?.clips}</span>
                </div>
                <div className="col-lg-3 col-md-5 col-sm-6 col-6 text-center countBox my-md-2 mb-2 me-2 pointerHand" onClick={()=>handleNavigate(3,'memes')}>
                    <h3>Memes</h3>
                    <span className="countSize">{totalCount[2]?.memes}</span>
                </div>
            {/* </div>
            <div className="row m-4"> */}
            <div className="col-lg-3 col-md-5 col-sm-6 col-5 text-center countBox my-md-2 mb-2 pointerHand " onClick={()=>handleNavigate(4,'jokes')}>
                    <h3>Jokes</h3>
                    <span className="countSize">{totalCount[3]?.jokes}</span>
                </div>
                <div className="col-lg-3 col-md-5 col-sm-6 col-6 text-center countBox my-md-2 mx-lg-3 mx-md-2 pointerHand" onClick={()=>handleNavigate(5,'ottupdates')}>
                    <h3>Ott Updates</h3>
                    <span className="countSize">{totalCount[4]?.ottupdates}</span>
                </div>
                <div className="col-lg-3 col-md-5 col-sm-6 col-6 text-center countBox my-md-2 mx-lg-3 mx-md-2 pointerHand"
                    onClick={()=>handleNavigate(7,'games')}>
                    <h3>Games</h3>
                    <span className="countSize">{totalCount[5]?.games}</span>
                </div>
            </div>
        </div>
        </div>
        </div>
    );
}

export default Home;