import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from 'react-s3';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import commonConfig from "../../../config/common-config";
import { thunk_action_createJokesData,thunk_action_editJokesData } from '../../../redux/actions/jokeAction/jokefetchaction'



const UploadJoke = ({ handleSubmit, activeCategory,jokesList,isCheck,editData}) => {
    const userDetails = useSelector(state => state.getuserdetails?.results) || [];
    const dispatch = useDispatch();
    const [modalIsOpen, setIsOpen] = useState(false);

    const [uploadUrl, setuploadUrl] = useState([]);
    const [uploadErrmsg, setuploadErrmgs] = useState('');
    const [loader, setloader] = useState(false);
    const [location, setlocation] = useState('');
    const [description, setdescription] = useState('');
    const [IsUpdate, setIsUpdate] = useState(false);
    const [editId, setEditId] = useState('');
    const [jokeurl, setjokeurl] = useState('');

    const openModal = (type) => {
        if (type === 'edit') {
            if (editData.length > 0) {
                let jokesmediaurl = editData[0]?.jokesmedia || '';

                // Extract filenames from URLs
                let jokesmedia = jokesmediaurl?.substring(jokesmediaurl?.lastIndexOf("/") + 1);

                let jokesData = {
                    category_id: editData[0]?.category_id,
                    jokesmedia: jokesmedia
                }
                // Set state variables
                setlocation(editData[0]?.location || '');
                setdescription(editData[0]?.description || '');
                setuploadUrl([jokesData]);
                setEditId(editData[0]?.id || '');
                setjokeurl(jokesmedia);
            }
            setIsUpdate(true);
            setIsOpen(true);
        } else {
            setuploadUrl([])
            setuploadErrmgs('');
            setlocation('');
            setdescription('');
            setEditId('')
            setIsUpdate(false);
            setIsOpen(true);
            setjokeurl('');
        }
    }

    const closeModal = () => {
        setIsOpen(false);
        setuploadUrl([]);
        setuploadErrmgs('');
        setlocation('');
        setdescription('');
        setjokeurl('');
    }

    const handleImgUpload = async (event) => {
        setuploadErrmgs('');
        const uploadData = event.target.files;
        const updatedUploadData = Array.from(uploadData).map((element) => {
            const uniqueId = uuidv4();
            const fileNameWithoutExtension = element.name.split('.').slice(0, -1).join('.'); // Remove extension
            const newFilename = `${fileNameWithoutExtension}_${uniqueId}.${element.name.split('.').pop()}`; // Add timestamp before extension
            return new File([element], newFilename, { type: element.type });
        });
        let config = commonConfig.config;
        let bucketUrls = [];
        for (const obj of updatedUploadData) {
            setloader(true);
            try {
                // eslint-disable-next-line
                const data = await uploadFile(obj, config);
                let stickerObj = { "jokesmedia": obj.name, "category_id": activeCategory }
                bucketUrls.push(stickerObj); // Assuming data has a 'location' property
                setjokeurl(updatedUploadData[0]?.name);
                setloader(false);
            } catch (err) {
                setloader(true)
                console.error(err, 'err5666');
            }
        }
        setuploadUrl(bucketUrls)
    }
    const validateForm = () => {
        let error = 0;
        if (uploadUrl.length === 0) {
            error++;
            setuploadErrmgs('Please Enter Valid Fields')
        } else {
            error = 0;
            setuploadErrmgs('');
        }

        return error;
    }
    const notify = (message, type) => {
        if (type === 'success') {
            toast.success(message);
        } else if (type === 'error') {
            toast.error(message);
        } else {
            toast(message);
        }
    };
    const handleUploadSubmit = async () => {
        let result = validateForm();
        if (result === 0) {
            if (IsUpdate) {
                const data = uploadUrl.map((item, index) => {
                    return {
                        ...item,
                        location: location || null,
                        description: description || null,
                        author: userDetails?.first_name || '',
                        id: editId,
                    };
                });
                const response = await dispatch(thunk_action_editJokesData(data));
                if (response) {
                    notify("Jokes Updated Successfully", 'success');
                    await handleSubmit();
                    setuploadErrmgs('');
                    await closeModal();
                } else {
                    notify("Something went wrong", 'error')
                }
            } else {
                const data = uploadUrl.map((item, index) => {
                    return {
                        ...item,
                        location: location || null,
                        description: description || null,
                        author: userDetails?.first_name || ''
                    };
                });
                const response = await dispatch(thunk_action_createJokesData(data));
                if (response) {
                    notify("Jokes Uploaded Successfully", 'success');
                    await handleSubmit();
                    setuploadErrmgs('');
                    await closeModal();
                } else {
                    notify("Something went wrong", 'error')
                }
            }
        } else {
            setuploadErrmgs('Please Enter Valid Fields');
        }

    }
    const handleonchange = async (id, event) => {
        if (id === 'location') {
            setlocation(event.target.value)
        } else if (id === 'description') {
            setdescription(event.target.value)
        }
    }


    return (
        <div>
            <div className='d-flex text-start'>
            <div className='addBtn' onClick={()=>openModal('add')}>+Add Jokes</div>
            {jokesList.length > 0 &&
                    <i className={`fa fa-edit ${isCheck.length === 1 ? 'editIcon mt-1 mx-3' : 'editIcondisable mt-1 mx-3'}`}
                        onClick={() => isCheck.length === 1 ? openModal('edit') : undefined} />
                }
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="content"
                contentLabel="Example Modal"
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <div className="text-end">
                    <span className="closeIcon" aria-hidden="true" onClick={() => closeModal()}>&times;</span>
                </div>
                {/* <div>I am a modal</div> */}
                <div className="row mt-4">
                    {uploadErrmsg !== '' ? <p style={{ color: 'red' }}>{uploadErrmsg}</p> : null}
                    <div id="uploadImg">
                        <label htmlFor="imageInput" className="form-label text-start d-block red-asterisk">Upload Jokes </label>
                        <input type="file" className="form-control" id="imageInput" accept=".png, .jpg, .jpeg" name="image"
                            multiple onChange={(e) => handleImgUpload(e)} />
                             {editData.length > 0 &&
                            <div className='mt-2'>
                                <a href={`${commonConfig.bucketUrl}${jokeurl}`} target='_blank' className='mt-2'>{jokeurl}</a>
                            </div>
                        }
                        <label htmlFor="location" className="form-label text-start d-block mt-1">Location </label>
                        <input type="text" className="form-control" id="location" name="location"
                            onChange={(e) => handleonchange('location', e)} value = {location}/>
                        <label htmlFor="description" className="form-label text-start d-block mt-1">Description </label>
                        <textarea className="form-control" id="description" name="description" rows="4"
                            cols="50"
                            onChange={(e) => handleonchange('description', e)} value={description}/>

                        {loader ?
                            <div className="text-end m-3">
                                <button className="btn btn-primary " type="button" disabled>
                                    <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                    Loading..
                                </button>
                            </div> :
                            <div className="text-end mt-3">
                                <button type="button" className="btn btn-primary mb-3" onClick={() => handleUploadSubmit()}>Upload</button>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
            <ToastContainer />
        </div>
    );
}

export default UploadJoke;