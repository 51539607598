import store from '../../store/store';
import { BASE_URL } from '../../baseUrl';
import axios from 'axios';

export const fetch_post = () => {
    return { type: "FETCH_USER", };
};

export const receive_error = () => {
    return { type: "RECEIVE_ERROR" };
};

export const getStickersData = post => {
    return {
        type: "getStickersData",
        getStickersData: post?.data
    };
};

export const thunk_action_getStickersData = (data) => {
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        },
        data: {},
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.get(`${BASE_URL}/api/stickers/list?category_id=${data?.categoryId}&limit=${data?.limit}&offset=${data?.offset}`, config)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(getStickersData(res));
                    return res;
                }
            })
            .catch(err => dispatch(receive_error()));
    };
};

export const createStickersData = post => {
    return {
        type: "createStickersData",
        createStickersData: post?.data
    };
};

export const thunk_action_createStickersData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/stickers/createStickers`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(createStickersData(res));
                    return res;
                };
            })
            .catch(err => dispatch(receive_error()));
    };
};


export const deleteStickersData = post => {
    return {
        type: "deleteStickersData",
        deleteStickersData: post?.data
    };
};

export const thunk_action_deleteStickersData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/stickers/deleteStickers`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(deleteStickersData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};


export const getAllCounts = post => {
    return {
        type: "getAllCounts",
        getAllCounts: post?.data
    };
};

export const thunk_action_getAllCounts = (data) => {
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        },
        data: {},
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.get(`${BASE_URL}/api/countall`, config)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(getAllCounts(res));
                    return res;
                }
            })
            .catch(err => dispatch(receive_error()));
    };
};


export const editStickersData = post => {
    return {
        type: "editStickersData",
        editStickersData: post?.data
    };
};

export const thunk_action_editStickersData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/stickers/editStickers`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(editStickersData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};