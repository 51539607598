import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';
import { createBrowserHistory } from 'history';
import Login from "./components/adminlog/login";
// import SidebarComponent from "./components/pages/sidebar/sidebar";
import Home from './components/pages/home/home';
import StickerComponent from './components/pages/stickers/stickers';
import MemesComponent from './components/pages/memes/memes';
import ClipsComponent from './components/pages/clips/clips';
import JokesComponent from './components/pages/jokes/jokes';
import GamesComponent from './components/pages/games/games';
import OttUpdate from './components/pages/ottupdates/ottupdate';
import ViewAllCategories from './components/pages/categories/categorieslist';
import WhatsappStatusComponent  from './components/pages/whatsappstatus/whatsappstatus'

function isAuthenticated() {
    const token = localStorage.getItem('authtoken'); // You can store the token in localStorage
   if(!!token){
    return !!token
   }else{
    window.location.href = "/"
   }
   return !!token
}


export default <Router history={createBrowserHistory}>
    <div className="App">
        <Switch>
            <Route exact path="/" component={Login}></Route>
            <Route exact path="/home"
                render={(data) => {
                    return isAuthenticated() ? <Home {...data} /> : <Login />;
                }} />
                <Route exact path="/stickers"
                render={(data) => {
                    return isAuthenticated() ? <StickerComponent {...data} /> : <Login />;
                }} />
                <Route exact path="/clips"
                render={(data) => {
                    return isAuthenticated() ? <ClipsComponent {...data} /> : <Login />;
                }} />
                <Route exact path="/memes"
                render={(data) => {
                    return isAuthenticated() ? <MemesComponent {...data} /> : <Login />;
                }} />
                <Route exact path="/jokes"
                render={(data) => {
                    return isAuthenticated() ? <JokesComponent {...data} /> : <Login />;
                }} />
                <Route exact path="/ottupdates"
                render={(data) => {
                    return isAuthenticated() ? <OttUpdate {...data} /> : <Login />;
                }} />
                <Route exact path="/viewallcategories/:id"
                render={(data) => {
                    return isAuthenticated() ? <ViewAllCategories {...data} /> : <Login />;
                }} />
                <Route exact path="/whatsappstatus"
                render={(data) => {
                    return isAuthenticated() ? <WhatsappStatusComponent {...data} /> : <Login />;
                }} />
                <Route exact path="/games"
                render={(data) => {
                    return isAuthenticated() ? <GamesComponent {...data} /> : <Login />;
                }} />
        </Switch>
    </div>
</Router>