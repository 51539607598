import store from '../../store/store';
import { BASE_URL } from '../../baseUrl';
import axios from 'axios';

export const fetch_post = () => {
    return { type: "FETCH_USER", };
};

export const receive_error = () => {
    return { type: "RECEIVE_ERROR" };
};

export const getWhatsappStatusData = post => {
    return {
        type: "getWhatsappStatusData",
        getWhatsappStatusData: post?.data
    };
};

export const thunk_action_getWhatsappStatusData = (data) => {
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        },
        data: {},
    };
    store.dispatch(fetch_post());
    return async function (dispatch, getState) {
        return axios.get(`${BASE_URL}/api/whatsappstatus/list?category_id=${data?.categoryId}&limit=${data?.limit}&offset=${data?.offset}`, config)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(getWhatsappStatusData(res));
                    return res;
                }
            })
            .catch(err => dispatch(receive_error()));
    };
};

export const createWhatsappStatusData = post => {
    return {
        type: "createWhatsappStatus",
        createWhatsappStatusData: post?.data
    };
};

export const thunk_action_createWhatsappStatusData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return async function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/whatsappstatus/createwhatsappstatus`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(createWhatsappStatusData(res));
                    return res;
                };
            })
            .catch(err => dispatch(receive_error()));
    };
};

export const editWhatsappStatusData = post => {
    return {
        type: "editWhatsappStatusData",
        editWhatsappStatusData: post?.data
    };
};

export const thunk_action_editWhatsappStatusData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/whatsapp/editwhatsappstatus`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(editWhatsappStatusData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};


export const deleteWhatsappStatusData = post => {
    return {
        type: "deleteWhatsappStatusData",
        deleteWhatsappStatusData: post?.data
    };
};

export const thunk_action_deleteWhatsappStatusData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return async function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/whatsappstatus/deletewhatsappstatus`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(deleteWhatsappStatusData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};


export const getAllCounts = post => {
    return {
        type: "getAllCounts",
        getAllCounts: post?.data
    };
};

export const thunk_action_getAllCounts = (data) => {
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        },
        data: {},
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.get(`${BASE_URL}/api/countall`, config)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(getAllCounts(res));
                    return res;
                }
            })
            .catch(err => dispatch(receive_error()));
    };
};
