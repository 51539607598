import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { thunk_action_loginUser } from '../../redux/actions/loginAction/loginfetchaction';
import './login.css'

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [userEmail, setuserEmail] = useState('xploree@keypoint-tech.com');
    const [password, setPassword] = useState('keypoint#123');
    const [errorMsg, setErrorMsg] = useState('');
    const [userEmailerr, setuserEmailerr] = useState('');
    const [passworderr, setPassworderr] = useState('');
    const [loader, setloader] = useState(false);

    const validateForm = () => {
        let error = 0;
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (userEmail === '' || password === '' || (!emailRegex.test(userEmail))) {
            error++;
            setErrorMsg('Please Enter Valid Fields')
        } else {
            error = 0;
            setErrorMsg('');
        }

        return error;
    }

    const handleChange = (event, id) => {
        setErrorMsg('');
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (id === 'userEmail') {
            if (event.target.value === '') {
                setuserEmailerr('please enter valid email address');
                setuserEmail('');
            } else {
                setuserEmail(event.target.value);
                setuserEmailerr('');
            }
        } else if (id === 'password') {
            if (event.target.value === '') {
                setPassworderr('please enter password');
                setPassword('');
            } else {
                setPassword(event.target.value);
                setPassworderr('')
            }
        }
    }

    const handleRedirect = async () => {
        setloader(true);
        let result = validateForm();
        if (result === 0) {
            let data = {
                email: userEmail,
                password: password
            }
            const response = await dispatch(thunk_action_loginUser(data));
            if (response.data !== undefined) {
                let token = response.data?.results;
                 await localStorage.setItem('authtoken', token);
                 setloader(false);
               history.push('/home');
            } else {
                setErrorMsg('Invalid Credentials');
                setloader(false);
                history.push('/');
            }
        } else {
            setloader(false);
            history.push('/');
        }
    }

    return (

        <div className='row container-fluid'>
            <div className="mx-auto col-12 col-md-6 col-sm-6 col-lg-4 loginForm">
                <h3 className='mt-2'>Login</h3>
                <p className='validField'>{errorMsg}</p>
                <div>
                    <label htmlFor="imageInput" className="form-label text-start d-block">Email<span className='validField'>*</span></label>
                    <input type="email" className={(userEmailerr !== '' || errorMsg !== '') ? "form-control inValidInput" : "form-control"} id="imageName" name="imagename"
                        value={userEmail} onChange={(event) => handleChange(event, 'userEmail')} />
                    {(userEmailerr !== '' && errorMsg === '') ? <p className='validField text-start'>{userEmailerr}</p> : null}
                    <label htmlFor="imageInput" className="form-label text-start d-block mt-2">Password<span className='validField'>*</span></label>
                    <div className="input-group">
                        <input type="password" className={(passworderr !== '' || errorMsg !== '') ? "form-control inValidInput" : "form-control"} id="imgDescription" name="imgDescription"
                            value={password}  onChange={(event) => handleChange(event, 'password')} />
                        {/* <span className="input-group-text">$</span> */}
                    </div>
                    {(passworderr !== '' && errorMsg === '') ? <p className='validField text-start'>{passworderr}</p> : null}
                </div>
                <div className='text-center mt-2'>
                    {loader ?
                        <button className="btn btn-primary " type="button" disabled>
                            <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> :
                        <button type="button" className="btn btn-primary"
                            onClick={() => handleRedirect()}>Submit</button>
                    }

                </div>
            </div>
        </div>
    );
}

export default Login;