import store from '../../store/store';
import { BASE_URL } from '../../baseUrl';
import axios from 'axios';

export const fetch_post = () => {
    return { type: "FETCH_USER", };
};

export const receive_error = () => {
    return { type: "RECEIVE_ERROR" };
};

export const getOttUpdateData = post => {
    return {
        type: "getOttUpdateData",
        getOttUpdateData: post?.data
    };
};

export const thunk_action_getOttUpdateData = (data) => {
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        },
        data: {},
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.get(`${BASE_URL}/api/ott/list?category_id=${data?.categoryId}&limit=${data?.limit}&offset=${data?.offset}`, config)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(getOttUpdateData(res));
                    return res;
                }
            })
            .catch(err => dispatch(receive_error()));
    };
};

export const createOttUpdateData = post => {
    return {
        type: "createOttUpdateData",
        createOttUpdateData: post?.data
    };
};

export const thunk_action_createOttUpdateData = (data) => {
    let headers = {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/ott/createottupdate`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(createOttUpdateData(res));
                    return res;
                };
            })
            .catch(err => dispatch(receive_error()));
    };
};

export const deleteOttUpdateData = post => {
    return {
        type: "deleteOttUpdateData",
        deleteOttUpdateData: post?.data
    };
};

export const thunk_action_deleteOttUpdateData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/ott/deleteottupdate`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(deleteOttUpdateData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};


export const editOttUpdateData = post => {
    return {
        type: "editOttUpdateData",
        eventditOttUpdateData: post?.data
    };
};

export const thunk_action_EditOttUpdateData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/ott/editottupdate`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(editOttUpdateData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};