import store from '../../store/store';
import { BASE_URL } from '../../baseUrl';
import axios from 'axios';

export const fetch_post = () => {
    return { type: "FETCH_USER", };
};

export const receive_error = () => {
    return { type: "RECEIVE_ERROR" };
};



export const createMemes = post => {
    return {
        type: "createMemes",
        createMemes: post?.data
    };
};

export const thunk_action_createMemes = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/memes/createMemes`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(createMemes(res));
                    return res;
                };
            })
            .catch(err => {
                let message = 'Category already exist'
                dispatch(receive_error());
                return { message };
            });
    };
};

export const getMemes = post => {
    return {
        type: "getMemes",
        getMemes: post?.data
    };
};

export const thunk_action_getMemes = (data) => {
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        },
        data: {},
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.get(`${BASE_URL}/api/memes/list?category_id=${data?.categoryId}&limit=${data?.limit}&offset=${data?.offset}`, config)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(getMemes(res));
                    return res;
                }
            })
            .catch(err => dispatch(receive_error()));
    };
};

export const deleteMeme = post => {
    return {
        type: "deleteMeme",
        deleteMeme: post?.data
    };
};

export const thunk_action_deleteMeme = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/memes/deleteMeme`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(deleteMeme(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};

export const editMemesData = post => {
    return {
        type: "editMemesData",
        editMemesData: post?.data
    };
};

export const thunk_action_editMemesData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/memes/editMeme`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(editMemesData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};