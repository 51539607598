import store from '../../store/store';
import { BASE_URL } from '../../baseUrl';
import axios from 'axios';

export const fetch_post = () => {
    return { type: "FETCH_USER", };
};

export const receive_error = () => {
    return { type: "RECEIVE_ERROR" };
};

export const getJokesData = post => {
    return {
        type: "getJokesData",
        getJokesData: post?.data
    };
};

export const thunk_action_getJokesData = (data) => {
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        },
        data: {},
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.get(`${BASE_URL}/api/jokes/list?category_id=${data?.categoryId}&limit=${data?.limit}&offset=${data?.offset}`, config)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(getJokesData(res));
                    return res;
                }
            })
            .catch(err => dispatch(receive_error()));
    };
};

export const createJokesData = post => {
    return {
        type: "createJokesData",
        createJokesData: post?.data
    };
};

export const thunk_action_createJokesData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/jokes/createJokes`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(createJokesData(res));
                    return res;
                };
            })
            .catch(err => dispatch(receive_error()));
    };
};


export const deleteJokesData = post => {
    return {
        type: "deleteJokesData",
        deleteJokesData: post?.data
    };
};

export const thunk_action_deleteJokesData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/jokes/deleteJokes`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(deleteJokesData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};


export const editJokesData = post => {
    return {
        type: "editJokesData",
        editJokesData: post?.data
    };
};

export const thunk_action_editJokesData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/jokes/editJokes`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(editJokesData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};