import store from '../../store/store';
import { BASE_URL } from '../../baseUrl';
import axios from 'axios';


export const fetch_post = () => {
    return { type: "FETCH_USER", };
};

export const receive_error = () => {
    return { type: "RECEIVE_ERROR" };
};


export const getCategoryList = post => {
    return {
        type: "getCategoryList",
        getCategoryList: post?.data
    };
};


export const thunk_action_getCategoryList = (data) => {
    let headers = {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/categorieslist`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(getCategoryList(res));
                    return res
                 } 
            })
            .catch(err => dispatch(receive_error()));
    };
};


export const createCategoryList = post => {
    return {
        type: "createCategoryList",
        createCategoryList: post?.data
    };
};


export const thunk_action_createCategoryList = (data) => {
    let headers = {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/category/createCategory`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(createCategoryList(res));
                    return res
                 } 
            })
            .catch(err => dispatch(receive_error()));
    };
};

export const editcategory = post => {
    return {
        type: "editcategory",
        editcategory: post?.data
    };
};

export const thunk_action_Editcategory = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/category/editCategory`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(editcategory(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};

export const deleteCategoryData = post => {
    return {
        type: "deleteCategoryData",
        deleteCategoryData: post?.data
    };
};

export const thunk_action_deletecategory = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/category/deleteCategory`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(deleteCategoryData(res));
                    return res;
                }
            })
            .catch(err => dispatch(receive_error()));
    };
};