import store from '../../store/store';
import { BASE_URL } from '../../baseUrl';
import axios from 'axios';

export const fetch_post = () => {
    return { type: "FETCH_USER", };
};

export const receive_error = () => {
    return { type: "RECEIVE_ERROR" };
};

export const loginUser = post => {
    return {
        type: "loginUser",
        loginUser: post?.data?.results
    };
};

export const thunk_action_loginUser = (data) => {
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/loginuser`,data )
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(loginUser(res));
                    return res;
                };
            })
            .catch(err => dispatch(receive_error()));
    };
};

export const getuserdetails = post => {
    return {
        type: "getuserdetails",
        getuserdetails: post?.data
    };
};

export const thunk_action_getuserdeatils = () => {
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        },
        data: {},
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.get(`${BASE_URL}/api/getuserdetails`, config)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(getuserdetails(res));
                    return res;
                }
            })
            .catch(err => dispatch(receive_error()));
    };
};