// import { combineReducers } from 'redux';
const initialState = {
    getuserdetails: [],
    loading: false,
    stickersData: [],
    createStickersData: [],
    getClipData: [],
    createClipData: [],
    loginuser: [],
    deleteClipData: [],
    deleteStickersData: [],
    createMemes: [],
    getMemes: [],
    deleteMeme: [],
    getJokesData: [],
    createJokesData: [],
    deleteJokesData: [],
    getOttUpdateData: [],
    createOttUpdateData: [],
    deleteOttUpdateData: [],
    getAllCounts: [],
    getCategoryList: [],
    createCategoryList: [],
    editOttUpdateData: [],
    editClipData: [],
    editStickersData: [],
    editMemesData: [],
    editJokesData: [],
    editcategory:[],
    deleteCategoryData:[],
    whatsappStatusData: [],
    createWhatsappStatusData: [],
    editWhatsappStatusData: [],
    deleteWhatsappStatusData: [],
    getGamesData: [],
    createGamesData: [],
    deleteGamesData: [],
    editGamesData: []
}

const asyncReducer = (state = initialState, action) => {
    switch (action.type) {
        case "getCategoryList":
            return Object.assign({}, state, {
                ...state,
                getCategoryList: action.getCategoryList,
                loading: false
            });
        case "createCategoryList":
            return Object.assign({}, state, {
                ...state,
                createCategoryList: action.createCategoryList,
                loading: false
            });
        case "getuserdetails":
            return Object.assign({}, state, {
                ...state,
                getuserdetails: action.getuserdetails,
                loading: false
            });
        case "loginUser":
            return Object.assign({}, state, {
                ...state,
                loginuser: action.loginUser,
                loading: false
            });
        case "getStickersData":
            return Object.assign({}, state, {
                ...state,
                stickersData: action.getStickersData,
                loading: false
            });
        case "createStickersData":
            return Object.assign({}, state, {
                ...state,
                createStickersData: action.createStickersData,
                loading: false
            });
        case "getClipData":
            return Object.assign({}, state, {
                ...state,
                getClipData: action.getClipData,
                loading: false
            });
        case "createClipData":
            return Object.assign({}, state, {
                ...state,
                getClipData: action.createClipData,
                loading: false
            });
        case "deleteClipData":
            return Object.assign({}, state, {
                ...state,
                deleteClipData: action.deleteClipData,
                loading: false
            });
        case "editClipData":
            return Object.assign({}, state, {
                ...state,
                editClipData: action.editClipData,
                loading: false
            });

        case "deleteStickersData":
            return Object.assign({}, state, {
                ...state,
                deleteStickersData: action.deleteStickersData,
                loading: false
            });
        case "createMemes":
            return Object.assign({}, state, {
                ...state,
                createMemes: action.createMemes,
                loading: false
            });
        case "getMemes":
            return Object.assign({}, state, {
                ...state,
                getMemes: action.getMemes,
                loading: false
            });
        case "deleteMeme":
            return Object.assign({}, state, {
                ...state,
                deleteMeme: action.deleteMeme,
                loading: false
            });
        case "getJokesData":
            return Object.assign({}, state, {
                ...state,
                getJokesData: action.getJokesData,
                loading: false
            });
        case "createJokesData":
            return Object.assign({}, state, {
                ...state,
                createJokesData: action.createJokesData,
                loading: false
            });
        case "deleteJokesData":
            return Object.assign({}, state, {
                ...state,
                deleteJokesData: action.deleteJokesData,
                loading: false
            });
        case "getOttUpdateData":
            return Object.assign({}, state, {
                ...state,
                getOttUpdateData: action.getOttUpdateData,
                loading: false
            });
        case "createOttUpdateData":
            return Object.assign({}, state, {
                ...state,
                createOttUpdateData: action.createOttUpdateData,
                loading: false
            });

        case "deleteOttUpdateData":
            return Object.assign({}, state, {
                ...state,
                deleteOttUpdateData: action.deleteOttUpdateData,
                loading: false
            });

        case "editOttUpdateData":
            return Object.assign({}, state, {
                ...state,
                editOttUpdateData: action.editOttUpdateData,
                loading: false
            });

        case "getAllCounts":
            return Object.assign({}, state, {
                ...state,
                getAllCounts: action.getAllCounts,
                loading: false
            });
        case "editStickersData":
            return Object.assign({}, state, {
                ...state,
                editStickersData: action.editStickersData,
                loading: false
            });
        case "editMemesData":
            return Object.assign({}, state, {
                ...state,
                editMemesData: action.editMemesData,
                loading: false
            });
        case "editJokesData":
            return Object.assign({}, state, {
                ...state,
                editJokesData: action.editJokesData,
                loading: false
            });
        case "editcategory":
            return Object.assign({}, state, {
                ...state,
                editcategory: action.editcategory,
                loading: false
            });
        case "deleteCategoryData":
            return Object.assign({}, state, {
                ...state,
                deleteCategoryData: action.deleteCategoryData,
                loading: false
            });
        case "getWhatsappStatusData":
            return Object.assign({}, state, {
                    ...state,
                    whatsappStatusData: action.getWhatsappStatusData,
                    loading: false
            });
        case "createWhatsappStatusData":
            return Object.assign({}, state, {
                    ...state,
                    createWhatsappStatusData: action.createWhatsappStatusData,
                    loading: false
            });

        case "editWhatsappStatusData":
                return Object.assign({}, state, {
                    ...state,
                    editWhatsappStatusData: action.editWhatsappStatusData,
                    loading: false
            });

        case "deleteWhatsappStatusData":
                return Object.assign({}, state, {
                    ...state,
                    deleteWhatsappStatusData: action.deleteWhatsappStatusData,
                    loading: false
            });

        case "getGamesData":
            return Object.assign({}, state, {
                ...state,
                getGamesData: action.getGamesData,
                loading: false
            });
        case "createGamesData":
            return Object.assign({}, state, {
                ...state,
                createGamesData: action.createGamesData,
                loading: false
            });
        case "deleteGamesData":
            return Object.assign({}, state, {
                ...state,
                deleteGamesData: action.deleteGamesData,
                loading: false
            });
        case "editGamesData":
            return Object.assign({}, state, {
                ...state,
                editGamesData: action.editGamesData,
                loading: false
            });

        default: return state
    }
}

// const rootReducer = combineReducers({
//     joke: jokeReducer
// });

export default asyncReducer;