import React from "react";
import CategoriesComponent from './categories/categories';
import SidebarComponent from '../sidebar/sidebar';
import HeaderComponent from '../header/header';

const JokesComponent = () => {

    return (
        <div className="container-fluid">
            <HeaderComponent />
            <div className="row flex-sm-nowrap">
                <SidebarComponent />
                <div className="col-lg-10 col-md-9 col-sm-9 col-12  py-3" id="submenu1">
                    <h3 className="text-center">CATEGORIES</h3>
                    <CategoriesComponent  />
                </div>
            </div>
        </div>
    );
}

export default JokesComponent;