import React from "react";
import { useHistory } from "react-router-dom";
import './header.css';

const HeaderComponent = () => {

    const history = useHistory();

    const handleLogOut = () => {
        localStorage.removeItem('authtoken');
        history.push("/");
    }

    return (
        <div>
            <div className="row headerNavbar">
                <div className="col-6 text-start">
                    <a href="/dashboard">
                        <img src="../assests/images/xploree.png" alt="xploree" style={{ width: '100px' }} />
                    </a>
                </div>
                <div className="col-6 text-end Cursorpointer" onClick={() => handleLogOut()}>Logout</div>
            </div>
        </div>
    );
}

export default HeaderComponent;