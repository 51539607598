import React, { useState } from "react";
import OttCategories from './category/category';
import SidebarComponent from '../sidebar/sidebar';
import HeaderComponent from '../header/header';
const OttUpdate = () => {

    return (
        <div className="container-fluid">
            <HeaderComponent />
            <div className="row flex-sm-nowrap">
                <SidebarComponent/>
                <div className="col-lg-10 col-md-9 col-sm-9 col-11  py-3" id="submenu1">
                    <h3 className="text-center">OTT Updates</h3>
                    <OttCategories />
                </div>
            </div>
        </div>
    );
}

export default OttUpdate;