import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { uploadFile } from 'react-s3';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import commonConfig from "../../../config/common-config";
import { thunk_action_createClipData, thunk_action_editClipData } from '../../../redux/actions/clipAction/clipfetchaction'
import './uploadVideo.css'


const VideoUploadPopover = ({ handleSubmit, activeCategory, isCheck, editData, videoClips }) => {
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.getuserdetails?.results) || [];
    const [modalIsOpen, setIsOpen] = useState(false);

    const [uploadUrl, setuploadUrl] = useState([]);
    const [uploadErrmsg, setuploadErrmgs] = useState('');
    const [loader, setloader] = useState(false);
    const [location, setlocation] = useState('');
    const [description, setdescription] = useState('');
    const [thumbnail, setthumbnail] = useState([]);
    const [videourl, setvideourl] = useState([]);
    const [IsUpdate, setIsUpdate] = useState(false);
    const [editId, setEditId] = useState('');
    const openModal = (type) => {
        if (type === 'edit') {
            if (editData.length > 0) {
                let thumbnailurl = editData[0]?.thumbnail || '';
                let clipmediaurl = editData[0]?.clipmedia || '';

                // Extract filenames from URLs
                let thumbnail = thumbnailurl?.substring(thumbnailurl?.lastIndexOf("/") + 1);
                let clipmedia = clipmediaurl?.substring(clipmediaurl?.lastIndexOf("/") + 1);

                let videoData = {
                    category_id: editData[0]?.category_id,
                    clipduration: editData[0]?.clipduration,
                    clipmedia: clipmedia
                }
                // Set state variables
                setlocation(editData[0]?.location || '');
                setdescription(editData[0]?.description || '');
                setthumbnail([thumbnail]);
                setuploadUrl([videoData]);
                setvideourl(clipmedia);
                setEditId(editData[0]?.id || '');
            }
            setIsUpdate(true);
            setIsOpen(true);
        } else {
            setuploadUrl([])
            setuploadErrmgs('');
            setthumbnail('');
            setlocation('');
            setdescription('');
            setvideourl([]);
            setEditId('')
            setIsUpdate(false);
            setIsOpen(true);
        }

    }

    const closeModal = () => {
        setIsOpen(false);
        setuploadUrl([])
        setuploadErrmgs('');
        setthumbnail('');
        setlocation('');
        setdescription('');
        setvideourl([]);
    }

    const getVideoDuration = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const media = new Audio(reader.result);
                media.onloadedmetadata = () => resolve(media.duration);
            };
            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        });

    const handleImgUpload = async (event, type) => {
        if (type === 'video') {
            setuploadErrmgs('');
            const uploadData = event.target.files;
            const updatedUploadData = Array.from(uploadData).map((element) => {
                const uniqueId = uuidv4();
                const fileNameWithoutExtension = element.name.split('.').slice(0, -1).join('.'); // Remove extension
                const newFilename = `${fileNameWithoutExtension}_${uniqueId}.${element.name.split('.').pop()}`; // Add timestamp before extension
                return new File([element], newFilename, { type: element.type });
            });
            let config = commonConfig.config;
            let bucketUrls = [];
            for (const obj of updatedUploadData) {
                setloader(true);
                try {
                    const videoDuration = await getVideoDuration(obj); // Call getVideoDuration here to get duration
                    // eslint-disable-next-line
                    const data = await uploadFile(obj, config);
                    let clipData = { 'clipmedia': obj.name, "category_id": activeCategory, 'clipduration': videoDuration.toFixed(0) }
                    bucketUrls.push(clipData); // Assuming data has a 'location' property
                    setvideourl(updatedUploadData[0]?.name);
                    setloader(false);
                } catch (err) {
                    setloader(true);
                    console.error(err, 'err5666');
                }
            }
            await setuploadUrl(bucketUrls)
        } else {
            const uploadData = event.target.files;
            const timestamp = new Date().getTime();
            const updatedUploadData = Array.from(uploadData).map((element) => {
                const fileNameWithoutExtension = element.name.split('.').slice(0, -1).join('.'); // Remove extension
                const newFilename = `${fileNameWithoutExtension}_${timestamp}.${element.name.split('.').pop()}`; // Add timestamp before extension
                return new File([element], newFilename, { type: element.type });
            });
            let config = commonConfig.config;
            let bucketUrls = [];
            for (const obj of updatedUploadData) {
                setloader(true);
                try {
                    // eslint-disable-next-line
                    const data = await uploadFile(obj, config);
                    let thumbnailData = obj.name
                    bucketUrls.push(thumbnailData); // Assuming data has a 'location' property
                    setloader(false);
                } catch (err) {
                    setloader(true);
                    console.error(err, 'thumbanailerr');
                }
            }
            await setthumbnail(bucketUrls)
        }

    }

    const validateForm = () => {
        let error = 0;
        if (uploadUrl.length === 0) {
            error++;
            setuploadErrmgs('Please Enter Valid Fields')
        } else {
            error = 0;
            setuploadErrmgs('');
        }

        return error;
    }

    const notify = (message, type) => {
        if (type === 'success') {
            toast.success(message);
        } else if (type === 'error') {
            toast.error(message);
        } else {
            toast(message);
        }
    };

    const handleUploadSubmit = async () => {
        let result = validateForm();
        if (result === 0) {
            if (IsUpdate) {
                const data = uploadUrl.map((item, index) => {
                    return {
                        ...item,
                        thumbnail: thumbnail[index] || '',
                        location: location || null,
                        description: description || null,
                        author: userDetails?.first_name || '',
                        id: editId,
                    };
                });
                const response = dispatch(thunk_action_editClipData(data));
                if (response) {
                    notify("Clips Updated Successfully", 'success');
                    await handleSubmit();
                    setuploadErrmgs('');
                    await closeModal();
                } else {
                    notify("Something went wrong", 'error')
                }

            } else {
                const data = uploadUrl.map((item, index) => {
                    return {
                        ...item,
                        thumbnail: thumbnail[index] || '',
                        location: location || null,
                        description: description || null,
                        author: userDetails?.first_name || '',
                    };
                });
                const response = await dispatch(thunk_action_createClipData(data));
                if (response) {
                    notify("Clips Uploaded Successfully", 'success');
                    await handleSubmit();
                    setuploadErrmgs('');
                    await closeModal();
                } else {
                    notify("Something went wrong", 'error')
                }
            }

        } else {
            setuploadErrmgs('Please Enter Valid Fields');
        }
    }

    const handleonchange = async (id, event) => {
        if (id === 'location') {
            setlocation(event.target.value)
        } else if (id === 'description') {
            setdescription(event.target.value)
        }
    }


    return (
        <div>
            <div className='d-flex text-start'>
                <div className='addBtn' onClick={() => openModal('add')}>+Add Videos</div>
                {videoClips.length > 0 &&
                    <i className={`fa fa-edit ${isCheck.length === 1 ? 'editIcon mt-1 mx-3' : 'editIcondisable mt-1 mx-3'}`}
                        onClick={() => isCheck.length === 1 ? openModal('edit') : undefined} />
                }
            </div>
            <div className='container-fluid'>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="content"
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <div className="text-end">
                        <span className="closeIcon" aria-hidden="true" onClick={() => closeModal()}>&times;</span>
                    </div>
                    {/* <div>I am a modal</div> */}
                    <div className="row mt-4">
                        {uploadErrmsg !== '' ? <p style={{ color: 'red' }}>{uploadErrmsg}</p> : null}
                        <div id="uploadImg">
                            <label htmlFor="imageInput" className="form-label text-start d-block red-asterisk">Clip Upload </label>
                            <input
                                id="video"
                                className="form-control"
                                type="file"
                                name="video_url"
                                accept="video/*"
                                multiple={!(editData.length > 0)}
                                onChange={(e) => handleImgUpload(e, 'video')}
                            />
                            {editData.length > 0 &&
                                <div className='mt-2'>
                                    <a href={`${commonConfig.bucketUrl}${videourl}`} target='_blank' >{videourl}</a>
                                </div>
                            }
                            {uploadUrl.length > 0 ?
                                <>
                                    <label htmlFor="thumbanail" className="form-label text-start d-block mt-1">Thumbnail </label>
                                    <input
                                        id="thumbanail"
                                        name="thumbanail"
                                        className="form-control"
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        multiple={!(editData.length > 0)}
                                        onChange={(e) => handleImgUpload(e, 'thumbnail')}
                                    />
                                    {editData.length > 0 &&
                                        <div className='mt-2'>
                                            <a href={`${commonConfig.bucketUrl}${thumbnail}`} target='_blank' className='mt-2'>{thumbnail}</a>
                                        </div>
                                    }
                                </>

                                : null}
                            <label htmlFor="location" className="form-label text-start d-block mt-1">Location </label>
                            <input type="text" className="form-control" id="location" name="location"
                                onChange={(e) => handleonchange('location', e)} value={location} />
                            <label htmlFor="description" className="form-label text-start d-block mt-1">Description </label>
                            <textarea className="form-control" id="description" name="description" rows="4"
                                cols="50"
                                onChange={(e) => handleonchange('description', e)} value={description} />
                            {loader ?
                                <div className="text-end m-3">
                                    <button className="btn btn-primary " type="button" disabled>
                                        <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                </div> :
                                <div className="text-end mt-3">
                                    <button type="button" className="btn btn-primary mb-3" onClick={() => handleUploadSubmit()}>Upload</button>
                                </div>
                            }
                        </div>
                    </div>
                </Modal>
                <ToastContainer />
            </div>
        </div>
    );
}

export default VideoUploadPopover;