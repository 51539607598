import React, { useState } from "react";
import MemesCategories from './categories/categories';
import SidebarComponent from '../sidebar/sidebar';
import HeaderComponent from '../header/header';
const MemesComponent = () => {
    return (
        <div className="container-fluid">
            <HeaderComponent />
            <div className="row flex-sm-nowrap">
                <SidebarComponent />
                <div className="col-lg-10 col-md-9 col-sm-9 col-12  py-3" id="submenu1">
                    <h3 className="text-center">MEMES</h3>
                    <MemesCategories />
                </div>
            </div>
        </div>
    );
}

export default MemesComponent;