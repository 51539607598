import store from '../../store/store';
import { BASE_URL } from '../../baseUrl';
import axios from 'axios';

export const fetch_post = () => {
    return { type: "FETCH_USER", };
};

export const receive_error = () => {
    return { type: "RECEIVE_ERROR" };
};

export const getGamesData = post => {
    return {
        type: "getGamesData",
        getGamesData: post?.data
    };
};

export const thunk_action_getGamesData = (data) => {
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        },
        data: {},
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.get(`${BASE_URL}/api/games/list?category_id=${data?.categoryId}&limit=${data?.limit}&offset=${data?.offset}`, config)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(getGamesData(res));
                    return res;
                }
            })
            .catch(err => dispatch(receive_error()));
    };
};

export const createGamesData = post => {
    return {
        type: "createGamesData",
        createGamesData: post?.data
    };
};

export const thunk_action_createGamesData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/games/createGames`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(createGamesData(res));
                    return res;
                };
            })
            .catch(err => dispatch(receive_error()));
    };
};


export const deleteGamesData = post => {
    return {
        type: "deleteGamesData",
        deleteGamesData: post?.data
    };
};

export const thunk_action_deleteGamesData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/games/deleteGames`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(deleteGamesData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};


export const editGamesData = post => {
    return {
        type: "editGamesData",
        editGamesData: post?.data
    };
};

export const thunk_action_editGamesData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/games/editGames`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(editGamesData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};