class ConfigService {

    config = {
        bucketName: 'xploree-cms-images',
        dirName: 'media', /* optional */
        region: 'ap-south-1',
        accessKeyId: 'AKIA3DIIQ3ZTQOWODOAN',
        secretAccessKey: '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',
    }

    bucketUrl = "https://xploree-cms-images.s3.amazonaws.com/media/"
}

export default new ConfigService();