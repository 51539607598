import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import Modal from 'react-modal';
import { uploadFile } from 'react-s3';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import commonConfig from "../../../config/common-config";
import { thunk_action_Editcategory,thunk_action_getCategoryList,thunk_action_deletecategory } from '../../../redux/actions/categoriesListAction/categoriesListaction';


const ViewAllCategories = ({ categoriesList }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [category, setcategory] = useState(categoriesList);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [type, setType] = useState('');
    const [formData,setformData] = useState({
        id:'',
        categoryname:'',
        logo:''
    });

    const [errors,setErrors]=useState({
        categoryname:'',
        logo:''
    })

    useEffect(() => {
        // handlegetcategories(data);
    }, [])

       const handlegetcategories = async ()=>{
        const locationpath = location?.pathname || '';
                    const type = locationpath.slice(1);
                    let data = {
                        "categorytype": type
                    }
        const response =  await dispatch(thunk_action_getCategoryList(data));
        let result = response.data?.results || [];
        setcategory(result);
       }
    const closedeleteModal =  () => {
        setformData({
            id:'',
            categoryname:'',
            logo:''
        });
        setType('');
        setIsOpen(false);
    }
    const notify = (message, type) => {
        if (type === 'success') {
            toast.success(message,{position: 'bottom-right'});
        } else if (type === 'error') {
            toast.error(message,{position: 'bottom-right'});
        } else {
            toast(message,{position: 'bottom-right'});
        }
    };
    const handleFormChange = (e)=>{
        const { name, value } = e.target;
        setformData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleUploadImage = async (event, id) => {
        try {
            const uploadData = event.target.files[0];

            if (uploadData) {
                // Generate a timestamp
                const timestamp = new Date().getTime();

                // Get the file name without extension
                const fileNameWithoutExtension = uploadData.name.split('.').slice(0, -1).join('.');

                // Create a new filename by appending the timestamp and the original extension
                const newFilename = `${fileNameWithoutExtension}_${timestamp}.${uploadData.name.split('.').pop()}`;

                // Create a new File object with the updated name
                const updatedFile = new File([uploadData], newFilename, { type: uploadData.type });

                let config = commonConfig.config;
                // Call your upload function or API endpoint to upload the file
                await uploadFile(updatedFile, config);
                    await setformData({
                        ...formData,
                        logo: newFilename
                    });
            }
        } catch (error) {
            console.error("Error during file upload:", error);
        }
    };


    const validateRequest = () => {
        let newErrors = {};

        // Validate categoryname (assuming it's required)
        if (formData.categoryname === '') {
            newErrors = { ...newErrors, categoryname: 'categoryname is required' };
        }
        // Validate categoryname (assuming it's required)
        if (formData.logo === '') {
            newErrors = { ...newErrors, logo: 'logo is required' };
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    };


    const handleSubmit = async () => {
        const validate = validateRequest();
        if(type === 'edit'){
            if(validate){
                const response = await dispatch(thunk_action_Editcategory(formData));
                if (response) {
                    setformData({
                        id:'',
                        categoryname:'',
                        logo:''
                    });
                    await handlegetcategories();
                    setIsOpen(false);
                    notify("Category Updated Successfully", 'success');
                } else {
                    notify("Something went wrong", 'error')
                }
            }  
        }else{
            let data = {id : formData.id};
            const response = await dispatch(thunk_action_deletecategory(data));
            console.log(response,'response')
            if (response?.data) {
                setformData({
                    id:'',
                    categoryname:'',
                    logo:''
                });
                await handlegetcategories();
                setIsOpen(false);
                notify("Category Deleted Successfully", 'success');
            } else {
                notify("Something went wrong", 'error')
            }
        }
    }
    const handleOpen = (type,data) => {
        setformData({
            id:data.id || '',
            categoryname:data.categoryname || '',
            logo:data.logo || ''
        })
        setType(type);
        setIsOpen(true);
    }

    return (
        <div>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Categoryname</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {category.map((list, index) => (
                        <tr key={index}>
                            <td>{list.categoryname}</td>
                            <td>
                                <i className="fa fa-edit mx-3 mt-1 editIcon" onClick={() => handleOpen('edit',list)} />
                                <i className="fa fa-trash-o trashIcon mt-1" onClick={() => handleOpen('delete',list)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closedeleteModal}
                className="content"
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}>
                <div className="float-end">
                    <span className="closeIcon" aria-hidden="true" onClick={() => closedeleteModal()}>&times;</span>
                </div>
                <div>

                    <div className="custom-modal-header my-4">
                        <h4>Category update</h4>
                    </div>
                    {type === 'edit' &&
                        <form>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="categoryname" className="form-label text-start d-block redAsterisk">Category Name </label>
                                    <input type="text" className="form-control" id="categoryname" name="categoryname"
                                    onChange={handleFormChange} 
                                    value={formData.categoryname}
                                    />
                            {errors.categoryname && <p className="error-message mt-2 invalidField">{errors.categoryname}</p>}
                                </div>
                                <div className="col-12 mt-3">
                                    <label htmlFor="logo" className="form-label text-start d-block redAsterisk">Logo</label>
                                    <input type="file" className="form-control" id="logo" aria-describedby="logo" accept=".png, .jpg, .jpeg, .webp, .svg"
                                            onChange={(event) => handleUploadImage(event, 'logo')} name="logo"/>
                                    {formData.logo !== '' && <span><a href={`${commonConfig.bucketUrl}${formData.logo}`} target="_blank">{formData.logo}</a></span>}
                            {errors.logo && <p className="error-message mt-2 invalidField">{errors.logo}</p>}

                                </div>
                            </div>
                        </form>
                        ||
                        <div className="text-center mt-4">Are you sure you want delete ?</div>
                    }
                    <div className="mt-4 text-end">
                        <button type="button" className="btn btn-primary mx-3 mb-3" onClick={() => closedeleteModal()}>Cancel</button>
                        <button type="button" className="btn btn-primary mx-3 mb-3" onClick={() => handleSubmit()}>Ok</button>
                    </div>
                </div>
            </Modal>
            <ToastContainer/>
        </div>

    );
}

export default ViewAllCategories;