import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useLocation } from "react-router-dom";
import VideoUploadPopover from './uploadVideo';
import { thunk_action_getClipData, thunk_action_deleteClipData, thunk_action_getClipCategory } from '../../../redux/actions/clipAction/clipfetchaction';
import { thunk_action_getuserdeatils } from '../../../redux/actions/loginAction/loginfetchaction';
import { thunk_action_getCategoryList } from '../../../redux/actions/categoriesListAction/categoriesListaction';
import { deleteFile } from 'react-s3';
import commonConfig from "../../../config/common-config";
import AddVideoCategory from './addcategory/addcategory';
import SidebarComponent from '../sidebar/sidebar';
import HeaderComponent from '../header/header';
import ViewAllCategories from '../categories/categorieslist';

const ClipsComponent = () => {
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5, // Number of items to show at a time
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    const location = useLocation();
    const dispatch = useDispatch();
    const limit = 8;
    const [videoClips, setVideoClips] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [deleteData, setDeleteData] = useState({});
    const [errormsg, seterrormsg] = useState('');
    const [clipCategory, setclipCategory] = useState([]);
    const [activeCategory, setactiveCategory] = useState(clipCategory[0]?.id || '');
    const [activeClass, setActiveclass] = useState(0);
    const [offset, setOffset] = useState(0);
    const [paginationId, setpaginationId] = useState(1);
    const [isCheck, setIsCheck] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [editData, seteditData] = useState([]);
    const totalPages = Array.from({ length: Math.ceil(totalCount / limit) }, (_, index) => index + 1);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(10);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const pageNumberLimit = 10;
    const [viewcategories, setviewcategories] = useState(false);
    useEffect(() => {
        dispatch(thunk_action_getuserdeatils());
        handleCategoryFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCategoryFetch = async () => {
        const locationpath = location?.pathname || '';
        const type = locationpath.slice(1);
        let data = {
            "categorytype": type
        }
        const response = await dispatch(thunk_action_getCategoryList(data));
        let result = response.data?.results || [];
        await setclipCategory(result);
        await setactiveCategory(result[0]?.id);
        await handleFetchData(result[0]?.id, offset);
    }

    const handleFetchData = async (categoryId, offsetValue) => {
        let data = {
            categoryId: categoryId,
            limit: limit,
            offset: offsetValue
        }
        const response = await dispatch(thunk_action_getClipData(data));
        let result = response.data?.results || [];
        await setTotalCount(response.data?.totalcount || 0);
        await setVideoClips(result);

    }
    const handleActive = async (id, index) => {
        if (activeClass !== index) {
            setIsCheckAll(false);
            setIsCheck([]);
            seteditData([]);
            setpaginationId(1);
            setOffset(0);
        }
        let offesetValue = activeClass !== index ? 0 : offset
        setActiveclass(index);
        setactiveCategory(id);
        setmaxPageNumberLimit(10);
        setminPageNumberLimit(0);
        await handleFetchData(id, offesetValue);

    }
    const handleSubmit = async () => {
        await handleFetchData(activeCategory, offset);
        setIsCheckAll(false);
        setIsCheck([]);
        seteditData([]);
    }
    const handleCategory = async () => {
        await handleCategoryFetch();
    }
    const handleDelete = async () => {
        let config = commonConfig.config;
        let deletedItems = [];
        const response = await dispatch(thunk_action_deleteClipData(isCheck));
        // Delete selected items
        if (response) {
            seterrormsg('');
            setIsCheckAll(false);
            setIsCheck([]);
            seteditData([]);
            const deletePromises = [];
            for (const id of isCheck) {
                const deleteData = videoClips.find((item) => item.id === id);
                if (deleteData?.clipmedia) {
                    let filename = deleteData?.clipmedia;
                    const urlParts = filename.split("/");
                    const filenameWithExtension = urlParts[urlParts.length - 1];
                    // Delete the file and add the promise to the array
                    deletePromises.push(
                        deleteFile(filenameWithExtension, config)
                            .then(async (response) => {
                                if (response.message === 'File Deleted') {
                                    deletedItems.push(id);
                                }
                            })
                            .catch(err => {
                                console.error(err, 'deleteerr');
                                seterrormsg('Some error occurred while deleting2');
                            })
                    );
                } else {
                    seterrormsg('Some error occur while deleting1');
                }
            }
            await Promise.all(deletePromises);
            // Update the UI and state after deletion
            await handleFetchedRecords(deletedItems);
        } else {
            seterrormsg('Some error occur while deleting0');
        }
    };



    const handleFetchedRecords = async (data) => {
        if (data.length > 0) {
            setIsCheckAll(false);
            setIsCheck([]);
            setpaginationId(1);
            await handleFetchData(activeCategory, 0);
            await closedeleteModal();
            //   setSelectedItems([]);
            seterrormsg('');
        } else {
            seterrormsg('Some error occurred while deleting');
        }
    }

    const openDeleteModal = (data) => {
        seterrormsg('');
        setDeleteData(data);
        setIsOpen(true);
    }

    const closedeleteModal = () => {
        setIsOpen(false);
    }


    const handlePageChange = async (id, index) => {
        if (paginationId !== id) {
            setIsCheckAll(false);
            setIsCheck([]);
            seteditData([]);
        }
        setpaginationId(id);
        let offsetVal = index * limit;
        setOffset(offsetVal);
        await handleFetchData(activeCategory, offsetVal);
    }

    const handleChecked = (objId, event) => {
        const { id, checked } = event.target;
        setIsCheck([...isCheck, id]);
        seteditData([...editData, objId]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
            seteditData(editData.filter(item => item?.id !== id));
        }

    }

    const handleSelectAll = (e) => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(videoClips.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
            seteditData([]);
        }
    };

    const handleNextPage = async () => {
        setIsCheckAll(false);
        setIsCheck([]);
        seteditData([]);
        if (paginationId < totalPages?.length) {
            let offsetVal = paginationId * limit;
            setOffset(offsetVal);
            setpaginationId(paginationId + 1);
            await handleFetchData(activeCategory, offsetVal);
        } else {
            return null
        }
        if (paginationId + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const handlePrevPage = async () => {
        setIsCheckAll(false);
        setIsCheck([]);
        seteditData([]);
        if (paginationId > 1) {
            let offsetVal = (paginationId - 2) * limit;
            setOffset(offsetVal);
            setpaginationId(paginationId - 1);
            await handleFetchData(activeCategory, offsetVal);
        } else {
            return null
        }
        if ((paginationId - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };
    const handleViewallCategories = async () => {
        await handleCategoryFetch();
        setActiveclass(0);
        setviewcategories(!viewcategories)
    }

    return (
        <div className="container-fluid">
        <HeaderComponent />
        <div className="row flex-sm-nowrap">
     <SidebarComponent/>
        <div className="col-lg-10 col-md-9 col-sm-9 col-12  py-3" id="submenu1">
            <h3 className="text-center">Video Clips</h3>
            <div className="container-fluid">
            <div className="d-flex justify-content-end">
                <div className="text-center mx-2">
                    <button type="button" className="btn btn-primary mb-3"
                        onClick={() => handleViewallCategories()}
                    >{viewcategories ? 'View ottupdates' : 'View all categories'}</button>
                </div>
                {!viewcategories && 
                <div className="text-center">
                <AddVideoCategory handleCategory={handleCategory} />
                </div>
                }
            </div>
            {viewcategories &&
                <ViewAllCategories categoriesList={clipCategory} />
            }
               {!viewcategories &&
            <div>
                <div className="mx-2">
                    {clipCategory.length > 4 ?
                        <Slider {...settings}>
                            {clipCategory.map((obj, index) => (
                                <div className={activeClass === index ? 'nav-link categoryNav capitalLetter' : 'nav-link pointerHand capitalLetter'}
                                    aria-current="page" key={index}
                                    onClick={() => handleActive(obj.id, index)}
                                >{obj.categoryname}</div>
                            ))}
                        </Slider>
                        :
                        <div className="row categoryGrid">
                            {clipCategory.map((obj, index) => (
                                <div className={activeClass === index ? 'nav-link categoryNav capitalLetter' : 'nav-link pointerHand capitalLetter'}
                                    aria-current="page" key={index}
                                    onClick={() => handleActive(obj.id, index)}
                                >{obj.categoryname}</div>
                            ))}
                        </div>
                    }

                </div>
                {clipCategory.length > 0 ?
                    <div>
                        <div className="d-flex mb-3 mt-4">
                            <VideoUploadPopover
                                handleSubmit={handleSubmit}
                                activeCategory={activeCategory}
                                isCheck={isCheck}
                                editData={editData}
                                videoClips={videoClips} />
                            {videoClips.length > 0 &&
                                <i className={isCheck.length > 0 ? "fa fa-trash-o trashIcon" : "fa fa-trash-o trashIcondisable"}
                                    onClick={() => isCheck.length > 0 ? openDeleteModal() : undefined} />
                            }
                        </div>
                        {videoClips.length > 0 &&
                            <div>


                                <input className="form-check-input mt-3" type="checkbox"
                                    id='selectedall' onChange={(event) => handleSelectAll(event)}
                                    checked={isCheckAll} />

                                <div className="row">
                                    {videoClips.map((obj, index) => (
                                        <div className="col-lg-3 col-md-4 col-sm-4 col-6 mt-4 d-flex" key={index}>

                                            <input className="form-check-input" type="checkbox" checked={isCheck.includes(obj.id)} name={obj.id}
                                                id={obj.id} onChange={(event) => handleChecked(obj, event)} />

                                            <video width="220" height="100" controls key={obj.clipmedia} poster={obj?.thumbnail || ''} style={{ margin: '0px 10px' }}>
                                                <source src={obj.clipmedia} type="video/mp4" />
                                            </video>

                                        </div>
                                    ))}
                                </div>

                            </div>
                            ||
                            <div className="text-center mt-3">No data found</div>
                        }


                        {(videoClips.length > 0 && totalCount > 8) &&
                            <nav aria-label="Page navigation example" className="mt-4 d-flex justify-content-center">
                                <ul className="pagination">
                                    <li className={paginationId === 1 ? "page-item disabled" : "page-item"}
                                        onClick={() => handlePrevPage()}>
                                        <a className="page-link" href="#" aria-label="Previous" aria-disabled="true">
                                            <span aria-hidden="true">Previous</span>
                                        </a>
                                    </li>
                                    {totalPages.map((number, index) =>
                                        (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) ?
                                            (
                                                <li key={index}
                                                    className={paginationId === index + 1 ? 'page-item active' : 'page-item'}
                                                    onClick={() => handlePageChange(index + 1, index)}>
                                                    <a className="page-link" href="#">
                                                        {index + 1}
                                                    </a>
                                                </li>
                                            ) : null
                                    )}
                                    <li className={paginationId === totalPages?.length ? "page-item disabled" : "page-item"}
                                        onClick={() => handleNextPage()}>
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>}

                        {/* <div className="text-start mt-4 col-lg-2 col-md-4 col-sm-4 col-6">
                        <VideoUploadPopover handleSubmit={handleSubmit} activeCategory={activeCategory} isCheck={isCheck}/>
                    </div> */}
                    </div> :
                    <div className="text-center">Please add category</div>
                }

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closedeleteModal}
                    className="content"
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <div className="float-end">
                        <span className="closeIcon" aria-hidden="true" onClick={() => closedeleteModal()}>&times;</span>
                    </div>
                    <div>
                        {errormsg !== '' ? <p>{errormsg}</p> : null}
                        <div className="text-center mt-4">Are you sure you want delete ?</div>
                        <div className="mt-4 text-end">
                            <button type="button" className="btn btn-primary mx-3 mb-3" onClick={() => closedeleteModal()}>Cancel</button>
                            <button type="button" className="btn btn-primary mx-3 mb-3" onClick={() => handleDelete()}>Ok</button>
                        </div>
                    </div>
                </Modal>
                  </div>
            }
            </div>
        </div>
        </div>
        </div>
    );
}

export default ClipsComponent;