import React, { useEffect, useState } from "react";
import { useHistory,useLocation } from "react-router-dom";
import './sidebar.css'

const SidebarComponent = () => {
    const location = useLocation();
    const history = useHistory();
    const menuList = [{ id: 1, menuName: 'home' },
    { id: 2, menuName: 'stickers' },
    { id: 3, menuName: 'clips'},
    { id: 4, menuName: 'memes'},
    { id: 5, menuName: 'jokes'},
    { id: 6, menuName: 'ottupdates'},
    { id: 7, menuName: 'whatsappstatus'},
    { id: 8, menuName: 'games' }
    ];

    const [activeclass, setActiveClass] = useState(0);
    const [listName, setListName] = useState({ id: 1, menuName: 'Home' });
    const [menuShow,setmenuShow]= useState(false);
    const [pathname,setpathname]=useState('');

   useEffect(()=>{
    const locationpath = location?.pathname;
    const newPathSlice = locationpath.slice(1);
    setpathname(newPathSlice);
     },[])

    const handleActive = (value, obj) => {
        let pathname = (obj?.menuName).toLowerCase();
        setActiveClass(value);
        setListName(obj);
        setmenuShow(false);
        history.push(`/${pathname}`);
    }

    const handleMenuShow = ()=>{
        setmenuShow(!menuShow);
    }

    return (
        <>
                <button className="btn btn-primary  m-4 d-sm-none w-auto" type="button"  aria-controls="collapseExample"
                onClick={()=> handleMenuShow()}>
                <i className="fa fa-bars menuBar"></i>
                </button>
                <div className={menuShow ? "collapse d-sm-none show mt-1" : "collapse d-sm-none mt-1"} id="collapseExample">
                <div className="card card-body">
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start divWidth" id="menu">
                                {menuList.map((obj, index) => (
                                    <li className="nav-item divWidth" key={index}>
                                        <div
                                            className={pathname === obj.menuName ? "nav-link border-bottom p-1 align-middle active pointer text-center" : "nav-link border-bottom p-1 align-middle pointer text-center"}
                                            onClick={() => handleActive(index, obj)}> <i className="fs-4 bi-house"></i> <span className="ms-1  d-sm-inline capital">{obj?.menuName}</span></div>
                                    </li>
                                ))}
                              
                            </ul>
                </div>
                </div>
                    <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark d-sm-block d-none">
                        <div className="d-flex flex-column align-items-center align-items-sm-start py-4 text-white min-vh-100">
                            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start divWidth" id="menu">
                                {menuList.map((obj, index) => (
                                    <li className="nav-item divWidth" key={index}>
                                        <div
                                            className={pathname === obj.menuName ? "nav-link p-0 m-2 align-middle active pointer text-center" : "nav-link m-2 p-0 align-middle pointer text-center"}
                                            onClick={() => handleActive(index, obj)}> <i className="fs-4 bi-house"></i> <span className="ms-1  d-sm-inline capital">{obj.menuName}</span></div>
                                    </li>
                                ))}
                              
                            </ul>
                            <hr />
                        </div>
                    </div>
                    
                    {/* {listName?.menuName === 'Home' ? <Home handleNavActive={handleActive} menuName="Home"/> :
                        listName?.menuName === 'Stickers' ? <StickerComponent handleNavActive={handleActive} menuName="Stickers"/> :
                            listName?.menuName === 'Clips' ? <ClipsComponent handleNavActive={handleActive} menuname="Clips"/> :
                                listName?.menuName === 'Memes' ? <MemesComponent handleNavActive={handleActive} menuName="Memes"/> :
                                    listName?.menuName === 'Jokes' ? <JokesComponent handleNavActive={handleActive} menuName="Jokes"/>  : 
                                    listName?.menuName === 'OttUpdates'?<OttUpdate handleNavActive={handleActive} menuName="OttUpdates"/> :
                                    null} */}

                {/* </div> */}
            {/* </div> */}
        </>


    );
}

export default SidebarComponent;