import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { uploadFile } from 'react-s3';
import commonConfig from "../../../config/common-config";
import { thunk_action_createOttUpdateData } from '../../../redux/actions/ottUpdatesAction/ottUpdateAction'


const UploadOttUpdate = ({ handleSubmit, activeCategory }) => {
    const userDetails = useSelector(state => state.getuserdetails?.results) || [];
    const dispatch = useDispatch();
    const [modalIsOpen, setIsOpen] = useState(false);

    const [uploadUrl, setuploadUrl] = useState([]);
    const [uploadErrmsg, setuploadErrmgs] = useState('');
    const [loader, setloader] = useState(false);
    const [location, setlocation] = useState('');
    const [description, setdescription] = useState('');

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
        setuploadUrl([]);
        setuploadErrmgs('');
        setlocation('');
        setdescription('')
    }

    const handleImgUpload = async (event) => {
        setuploadErrmgs('');
        const uploadData = event.target.files[0];
        console.log('uploadDataxcel',uploadData)
        // const timestamp = new Date().getTime();
        // const updatedUploadData = Array.from(uploadData).map((element) => {
        //     const fileNameWithoutExtension = element.name.split('.').slice(0, -1).join('.'); // Remove extension
        //     const newFilename = `${fileNameWithoutExtension}_${timestamp}.${element.name.split('.').pop()}`; // Add timestamp before extension
        //     return new File([element], newFilename, { type: element.type });
        // });
        // let config = commonConfig.config;
        // let bucketUrls = [];
        // for (const obj of updatedUploadData) {
        //     setloader(true);
        //     try {
        //          // eslint-disable-next-line
        //         const data = await uploadFile(obj, config);
        //         let memedata = { "memesmedia": obj.name, "category_id": activeCategory }
        //         bucketUrls.push(memedata); // Assuming data has a 'location' property
        //         setloader(false)
        //     } catch (err) {
        //         setloader(true)
        //         console.error(err, 'err5666');
        //     }
        // }
         setuploadUrl(uploadData)
    }

    const handleonchange = async (id, event) => {
        if (id === 'location') {
            setlocation(event.target.value)
        } else if (id === 'description') {
            setdescription(event.target.value)
        }
    }

    const validateForm = () => {
        let error = 0;
        if (uploadUrl.length === 0) {
            error++;
            setuploadErrmgs('Please Enter Valid Fields')
        } else {
            error = 0;
            setuploadErrmgs('');
        }

        return error;
    }

    const handleUploadSubmit = async () => {
  let data = {
   file :uploadUrl
  }
    //  if(uploadUrl.length > 0){
             await dispatch(thunk_action_createOttUpdateData(data));
            await handleSubmit();
            setuploadErrmgs('');
            closeModal();
    //  }


        // let result = validateForm();
        // if (result === 0) {
        //     const data = uploadUrl.map((item, index) => {
        //         return {
        //             ...item,
        //             location: location || null,
        //             description: description || null,
        //             author: userDetails?.first_name || ''
        //         };
        //     });
        //     await dispatch(thunk_action_createOttUpdateData(data));
        //     await handleSubmit();
        //     setuploadErrmgs('');
        //     closeModal();
        // } else {
        //     setuploadErrmgs('Please Enter Valid Fields');
        // }

    }


    return (
        <div>
            <div className='addBtn' onClick={openModal}>+Add OttUpdates</div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="content"
                contentLabel="Example Modal"
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <div className="text-end">
                    <span className="closeIcon" aria-hidden="true" onClick={() => closeModal()}>&times;</span>
                </div>
                {/* <div>I am a modal</div> */}
                <div className="row mt-4">
                    {uploadErrmsg !== '' ? <p style={{ color: 'red' }}>{uploadErrmsg}</p> : null}

                    <div id="uploadImg">
                        <label htmlFor="imageInput" className="form-label text-start d-block red-asterisk">Upload Ottupdates</label>
                        <input type="file" className="form-control" id="imageInput" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" name="image"  onChange={(e) => handleImgUpload(e)} />
                        {/* <label htmlFor="location" className="form-label text-start d-block mt-1">Location </label>
                        <input type="text" className="form-control" id="location" name="location"
                            onChange={(e) => handleonchange('location', e)} />
                        <label htmlFor="description" className="form-label text-start d-block mt-1">Description </label>
                        <textarea className="form-control" id="description" name="description" rows="4"
                            cols="50"
                            onChange={(e) => handleonchange('description', e)} /> */}
                        {loader ?
                            <div className="text-end m-3">
                                <button className="btn btn-primary " type="button" disabled>
                                    <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                    Loading..
                                </button>
                            </div> :
                            <div className="text-end mt-3">
                                <button type="button" className="btn btn-primary mb-3" onClick={() => handleUploadSubmit()}>Upload</button>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default UploadOttUpdate;