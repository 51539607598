import store from '../../store/store';
import { BASE_URL } from '../../baseUrl';
import axios from 'axios';


export const fetch_post = () => {
    return { type: "FETCH_USER", };
};

export const receive_error = () => {
    return { type: "RECEIVE_ERROR" };
};

export const getClipData = post => {
    return {
        type: "getClipData",
        getClipData: post?.data?.results
    };
};

export const thunk_action_getClipData = (data) => {
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        },
        data: {},
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.get(`${BASE_URL}/api/clips/list?category_id=${data?.categoryId}&limit=${data?.limit}&offset=${data?.offset}`, config)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(getClipData(res));
                    return res
                };
            })
            .catch(err => dispatch(receive_error()));
    };
};

export const createClipData = post => {
    return {
        type: "createClipData",
        createClipData: post?.data
    };
};

export const thunk_action_createClipData = (data) => {
    let headers = {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
        };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/clips/createClip`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(createClipData(res));
                    return res;
                 } 
            })
            .catch(err => dispatch(receive_error()));
    };
};


export const deleteClipData = post => {
    return {
        type: "deleteClipData",
        deleteClipData: post?.data
    };
};

export const thunk_action_deleteClipData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/clips/deleteClip`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(deleteClipData(res));
                    return res;
                }
            })
            .catch(err => dispatch(receive_error()));
    };
};


export const editClipData = post => {
    return {
        type: "editClipData",
        editClipData: post?.data
    };
};

export const thunk_action_editClipData = (data) => {
    let headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authtoken')}`
    };
    store.dispatch(fetch_post());
    return function (dispatch, getState) {
        return axios.post(`${BASE_URL}/api/clips/editClip`, data, { headers: headers })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error("No such user found!!");
                } else {
                    dispatch(editClipData(res));
                    return res;
                };
            })
            .catch(err => {
                dispatch(receive_error());
            });
    };
};
